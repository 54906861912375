// App.tsx
import React, { useEffect, useState } from 'react';
import './App.css';
const genRandomNumber = () => {
  const randomNumber = Math.floor(Math.random() * 282);
  const formattedNumber = randomNumber.toString().padStart(3, "0");
  return formattedNumber;
};
const App: React.FC = () => {
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );

  const [randomNumber, setRandomNumber] = useState(genRandomNumber());
  const [remainingMinutes, setRemainingMinutes] = useState(95);
  const [fiveMinutesAgoString, setFiveMinutesAgoString] = useState("");


  const [textFontSize, setTextFontSize] = useState(0);
  const [textTop, setTextTop] = useState(0);
  const [textLeft, setTextLeft] = useState(0);

  const [randomnFontSize, setRandomnFontSize] = useState(0);
  const [randomnTop, setRandomnTop] = useState(0);
  const [randomnLeft, setRandomnLeft] = useState(0);

  const [attivatoIlFontSize, setAttivatoIlFontSize] = useState(0);
  const [attivatoIlTop, setAttivatoIlTop] = useState(0);
  const [attivatoIlLeft, setAttivatoIlLeft] = useState(0);

  const [tempoRestanteTop, setTempoRestanteTop] = useState(0);
  const [tempoRestanteLeft, setTempoRestanteLeft] = useState(0);

  const [emessoIlTop, setEmessoIlTop] = useState(0);
  const [emessoIlTopLeft, setEmessoIlTopLeft] = useState(0);

  const [codiceTicketTop, setCodiceTicketTop] = useState(0);
  const [codiceTicketTopLeft, setCodiceTicketTopLeft] = useState(0);

  const [videoTop, setVideoTop] = useState(0);
  const [videoLeft, setVideoLeft] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);

  const calculateSizes = () => {
    const ratio = windowWidth / 1080;
    const calculatedImageHeight = 3735 * ratio;
    console.log(window.innerWidth, calculatedImageHeight);
    const imageWidth = window.innerWidth;
    //Moltiplicazioni doppie perchè prima era un'altra risoluzione e per motivi di tempo ho fatto così
    setTextFontSize(Math.floor(imageWidth * 0.055));
    setTextTop(Math.floor(calculatedImageHeight * 0.6873 * 0.703));
    setTextLeft(Math.floor(imageWidth * 0.22));
    setRandomnFontSize(Math.floor(imageWidth * 0.055));
    setRandomnTop(Math.floor(calculatedImageHeight * 0.688 * 0.703));
    setRandomnLeft(Math.floor(imageWidth * 0.768));
    setAttivatoIlFontSize(Math.floor(imageWidth * 0.038));
    setAttivatoIlTop(Math.floor(calculatedImageHeight * 0.8815 * 0.685));
    setAttivatoIlLeft(Math.floor(imageWidth * 0.34));
    setTempoRestanteTop(Math.floor(calculatedImageHeight * 0.854 * 0.6854));
    setTempoRestanteLeft(Math.floor(imageWidth * 0.469));
    setEmessoIlTop(Math.floor(calculatedImageHeight * 0.945 * 0.6775));
    setEmessoIlTopLeft(Math.floor(imageWidth * 0.3));
    setCodiceTicketTop(Math.floor(calculatedImageHeight * 0.948 * 0.995));
    setCodiceTicketTopLeft(Math.floor(imageWidth * 0.68));
    setVideoTop(Math.floor(calculatedImageHeight * 0.653 * 0.735));
    setVideoLeft(Math.floor(imageWidth * 0.124));
    setVideoWidth(Math.floor(imageWidth * 0.76));
  };



  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [windowHeight, setWindowwindowHeight] = useState<number>(window.innerWidth);


  useEffect(() => {


    const calculateFiveMinutesAgo = () => {
      const fiveMinutesAgo = new Date();
      fiveMinutesAgo.setMinutes(fiveMinutesAgo.getMinutes() - 5);

      const hours = fiveMinutesAgo.getHours();
      const minutes = fiveMinutesAgo.getMinutes();

      const formattedHours = hours < 10 ? "0" + hours : hours;
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

      return formattedHours + ":" + formattedMinutes;
    };

    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    const minuteInterval = setInterval(() => {
      setRemainingMinutes((prevMinutes) => prevMinutes - 1);
    }, 60000);

    setFiveMinutesAgoString(calculateFiveMinutesAgo());
    calculateSizes();

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowwindowHeight(window.innerHeight);
      calculateSizes();

    };

    // Attacca l'event listener all'evento di ridimensionamento della finestra
    window.addEventListener('resize', handleResize);

    // Pulisce l'event listener quando il componente viene smontato

    return () => {
      window.removeEventListener('resize', handleResize);

      clearInterval(interval);
      clearInterval(minuteInterval);
    };
  }, []);

  return (
    <div className="app">
      <div className="app-content">
        {/* Il tuo contenuto scrollabile va qui */}
        <div className="overlay-text">
          <p style={{ position: 'absolute', fontSize: attivatoIlFontSize, fontWeight: 'bold', top: attivatoIlTop,whiteSpace: 'nowrap',   left: attivatoIlLeft }}> {getCurrentDate()} - {fiveMinutesAgoString}</p>

          <p style={{ position: 'absolute', fontSize: attivatoIlFontSize, fontWeight: 'bold', top: tempoRestanteTop, left: tempoRestanteLeft,whiteSpace: 'nowrap',color: 'white' }}>{Math.floor(remainingMinutes / 60)}h {remainingMinutes % 60}min</p>
          <p style={{ position: 'absolute', fontSize: attivatoIlFontSize * 1.1, top: codiceTicketTop, left: codiceTicketTopLeft, whiteSpace: 'nowrap',color: 'lightgray' }}>7200/17807</p>
          <p style={{ position: 'absolute', fontSize: attivatoIlFontSize, top: emessoIlTop, left: emessoIlTopLeft, whiteSpace: 'nowrap',color: 'black' }}>{getCurrentDate()} - {fiveMinutesAgoString}</p>
          <p style={{ position: 'absolute', fontSize: textFontSize, top: textTop, left: textLeft, whiteSpace: 'nowrap',color: '#005f8a' }}> {currentTime}</p>
          <p style={{ position: 'absolute', fontSize: randomnFontSize, top: randomnTop, left: randomnLeft,whiteSpace: 'nowrap', color: 'black' }}> {randomNumber}</p>
          <video style={{ position:'absolute', width: videoWidth, height: 210, left: videoLeft, top: videoTop}} width="640" height="360" autoPlay playsInline loop muted src="./video.mp4"> 

          </video>

        </div>
        <img src="./hq.png" alt="Example" />
        {/* Aggiungi altro contenuto se necessario */}
      </div>
    </div>

  );
};

export default App;
